.firebaseUi {
  width: 100%;
  box-shadow: none;
}

.firebaseui-form-actions .mdl-button--raised.mdl-button--colored.firebaseui-button {
  background: #2980B9;
}

.firebaseui-container {
  margin-bottom: 15px;
  min-height: 150px;
  padding-top: 10px;
  box-shadow: none !important;
}

.mdl-progress>.bufferbar {
  background-image: linear-gradient(to right, rgba(255, 255, 255, .7), rgba(255, 255, 255, .7)), linear-gradient(to right, #2980B9, #2980B9) !important;
  z-index: 0;
  left: 0
}
